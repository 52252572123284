import Agent from 'infrastructure/agent';
import tasksMapper from 'features/projects/tasks/tasks.mappers';

const getAllTaskTemplates = () => Agent.get('/template/tasks');

const addTaskTemplates = (projectId, taskIds) =>
  Agent.post(`/template/tasks/project/${projectId}`, taskIds);

const getTaskById = (projectId, taskId) =>
  Agent.get(`/project/${projectId}/tasks/${taskId}`);

const updateTask = (projectId, taskId, task) =>
  Agent.put(`/project/${projectId}/tasks/${taskId}`, tasksMapper.Task.to(task));

const updateTaskServices = (projectId, taskId, serviceIds) =>
  Agent.post(`/project/${projectId}/tasks/${taskId}/services`, serviceIds);

const createTask = (projectId, task) =>
  Agent.post(`/project/${projectId}/tasks`, tasksMapper.Task.to(task));

const deleteTask = (projectId, taskId) =>
  Agent.delete(`/project/${projectId}/tasks/${taskId}`);

const getAllTasks = (projectId) => Agent.get(`/project/${projectId}/tasks`);

const getAllTasksWithProjects = (projectId) =>
  Agent.get(`/project/${projectId}/tasks/GetTasksWithProjects`);

const updateTaskSequence = (projectId, taskId, itemSequence) =>
  Agent.post(`/project/${projectId}/tasks/${taskId}/sequence`, itemSequence);

const copyTask = (projectId, taskId) =>
  Agent.post(`/project/${projectId}/tasks/${taskId}/copy`);

const getWellDetailForProjectAndTask = (projectId, taskId) =>
  Agent.get(`/project/${projectId}/tasks/${taskId}/wellbore/detail`);

const updateWellDetailForProjectAndTask = (projectId, taskId, wellboreDetail) =>
  Agent.put(
    `/project/${projectId}/tasks/${taskId}/wellbore/detail`,
    wellboreDetail,
  );

const saveCableToTask = (taskCable, projectId, taskId) =>
  Agent.post(
    `/project/${projectId}/tasks/${taskId}/surfaceequipment/savecable`,
    taskCable,
  );

const saveSurfaceToolToTask = (taskTool, projectId, taskId) =>
  Agent.post(
    `/project/${projectId}/tasks/${taskId}/surfaceequipment/savesurface`,
    taskTool,
  );

const deleteCableFromTask = (taskCable, projectId, taskId) =>
  Agent.delete(
    `/project/${projectId}/tasks/${taskId}/surfaceequipment/deletecable/${taskCable.CableId}`,
    taskCable,
  );

const getCablesForTask = (projectId, taskId) =>
  Agent.get(`/project/${projectId}/tasks/${taskId}/surfaceequipment/getcables`);

const getSurfaceEquipmentForTask = (projectId, taskId) =>
  Agent.get(
    `/project/${projectId}/tasks/${taskId}/surfaceequipment/getsurfaces`,
  );

const deleteSurfaceFromTask = (surfaceEquipment, projectId, taskId) =>
  Agent.delete(
    `/project/${projectId}/tasks/${taskId}/surfaceequipment/deletesurface/${surfaceEquipment.surfaceId}`,
  );

const saveCableSerialNumberToTask = (cableSerialNumberDto) =>
  Agent.put('/Cable/task/individual-serial-number', cableSerialNumberDto);

const saveSurfaceEquipmentSerialNumber = (surfaceEquipmentSerialNumberDto) =>
  Agent.put(
    '/Cable/surfacequipment/individual-serial-number',
    surfaceEquipmentSerialNumberDto,
  );

const getDataAcquisitionForTask = (projectId, taskId) =>
  Agent.get(`/project/${projectId}/tasks/${taskId}/dataacquisition`);

const updateDataAcquisitionForTask = (
  projectId,
  taskId,
  dataAcquisitionId,
  dataAcquisition,
) =>
  Agent.put(
    `/project/${projectId}/tasks/${taskId}/dataacquisition/${dataAcquisitionId}`,
    dataAcquisition,
  );

const getDebrisTypes = (projectId, taskId) =>
  Agent.get(
    `/project/${projectId}/tasks/${taskId}/dataacquisition/debristypes`,
  );

const getGlycolTypes = (projectId, taskId) =>
  Agent.get(
    `/project/${projectId}/tasks/${taskId}/dataacquisition/glycoltypes`,
  );

const getGreaseTypes = (projectId, taskId) =>
  Agent.get(
    `/project/${projectId}/tasks/${taskId}/dataacquisition/greasetypes`,
  );

const getFrictionReducerTypes = (projectId, taskId) =>
  Agent.get(
    `/project/${projectId}/tasks/${taskId}/dataacquisition/frictionreducertypes`,
  );

const getInjectionMediums = (projectId, taskId) =>
  Agent.get(
    `/project/${projectId}/tasks/${taskId}/dataacquisition/injectionmediums`,
  );

const getProductionMediums = (projectId, taskId) =>
  Agent.get(
    `/project/${projectId}/tasks/${taskId}/dataacquisition/productionmediums`,
  );

const getTaskFluids = (projectId, taskId) =>
  Agent.get(`/project/${projectId}/tasks/${taskId}/dataacquisition/fluids`);

const getDefaultTaskFluid = (projectId, taskId) =>
  Agent.get(
    `/project/${projectId}/tasks/${taskId}/dataacquisition/fluids/default`,
  );

const deleteTaskFluid = (projectId, taskId, fluidId) =>
  Agent.delete(
    `/project/${projectId}/tasks/${taskId}/dataacquisition/fluids/${fluidId}`,
  );

export const updateTaskFluid = (projectId, taskId, fluidObj) =>
  Agent.put(
    `/project/${projectId}/tasks/${taskId}/dataacquisition/fluids/${fluidObj.fluidId}`,
    fluidObj,
  );

export const addTaskFluid = (projectId, taskId, fluidObj) =>
  Agent.post(
    `/project/${projectId}/tasks/${taskId}/dataacquisition/fluids`,
    fluidObj,
  );

export const getExplosives = (projectId, taskId) =>
  Agent.get(`/project/${projectId}/tasks/${taskId}/dataacquisition/explosives`);

export const updateExplosives = (projectId, taskId, data) =>
  Agent.post(
    `/project/${projectId}/tasks/${taskId}/dataacquisition/explosives/update`,
    data,
  );

const updateSerialNumbersFromTask = (
  projectId,
  fromTaskId,
  toTaskId,
  toToolStringId,
) =>
  Agent.post(
    `/project/${projectId}/tasks/fromTask/${fromTaskId}/toTask/${toTaskId}/toToolString/${toToolStringId}`,
  );

const updateTaskSEFromToTask = (projectId, fromTaskId, toTaskId) =>
  Agent.post(
    `/project/${projectId}/tasks/fromTask/${fromTaskId}/toTask/${toTaskId}/surface-equipment-serials`,
  );

const taskService = {
  getAllTaskTemplates,
  updateTaskSequence,
  getTaskById,
  updateTask,
  createTask,
  deleteTask,
  getAllTasks,
  copyTask,
  addTaskTemplates,
  getAllTasksWithProjects,
  getWellDetailForProjectAndTask,
  updateWellDetailForProjectAndTask,
  updateTaskServices,
  saveCableToTask,
  deleteCableFromTask,
  getCablesForTask,
  getSurfaceEquipmentForTask,
  saveSurfaceToolToTask,
  deleteSurfaceFromTask,
  saveCableSerialNumberToTask,
  saveSurfaceEquipmentSerialNumber,
  getGreaseTypes,
  getGlycolTypes,
  getDebrisTypes,
  getInjectionMediums,
  getProductionMediums,
  getFrictionReducerTypes,
  getDataAcquisitionForTask,
  updateDataAcquisitionForTask,
  getDefaultTaskFluid,
  getTaskFluids,
  deleteTaskFluid,
  updateTaskFluid,
  addTaskFluid,
  getExplosives,
  updateExplosives,
  updateSerialNumbersFromTask,
  updateTaskSEFromToTask,
};

export default taskService;
