import { List } from 'immutable';

import { WORK_ITEM_STATUS } from 'app/app.constants';
import { toolAssetHistoryEventToString } from 'features/equipment/equipment.helpers';

export const EQUIPMENT_ACTIVE_TAB = {
  CABLES: 'CABLES',
  MWL_TOOLS: 'MWL_TOOLS',
  EWL_TOOLS: 'EWL_TOOLS',
  BHA_TEMPLATES: 'BHA_TEMPLATES',
  TOOL_ASSEMBLIES: 'TOOL_ASSEMBLIES',
  THIRD_PARTY_TOOLS: 'THIRD_PARTY_TOOLS',
  SURFACE_EQUIPMENT: 'SURFACE_EQUIPMENT',
};

export const PROJECT_DETAILS_ACTIVE_TAB = {
  PROJECT_DETAILS: 'PROJECT_DETAILS',
  TEAM: 'TEAM',
  DOCUMENTS: 'DOCUMENTS',
  RISKS: 'RISKS',
  PROCEDURES_CHECKLISTS: 'PROCEDURES_CHECKLISTS',
  WELL: 'WELL',
};

export const PROJECT_WELLBORE_ACTIVE_TAB = {
  OVERVIEW: 'OVERVIEW',
  DETAILS: 'DETAILS',
  SECTIONS: 'SECTIONS',
};

export const EQUIPMENT_ACTIONS = {
  PAGE_LOADED: 'PAGE_LOADED',
  CREATE_TOOL: 'CREATE_TOOL',
  CREATE_SURFACE_EQUIPMENT: 'CREATE_SURFACE_EQUIPMENT',
  UPDATE_TOOL: 'UPDATE_TOOL',
  CREATE_SURFACE_UPDATE_TOOL: 'CREATE_SURFACE_UPDATE_TOOL',
  UPLOAD_IMAGE: 'UPLOAD_IMAGE',
  DELETE_IMAGE: 'DELETE_IMAGE',
  DELETE_SURFACE_EQUIPMENT_IMAGE: 'DELETE_SURFACE_EQUIPMENT_IMAGE',
  SEARCH_TOOLS: 'SEARCH_TOOLS',
  TOOLS_LOADED: 'TOOLS_LOADED',
  SEARCH_EQUIPMENT: 'SEARCH_EQUIPMENT',
  SEARCH_CABLES: 'SEARCH_CABLES',
  CABLES_LOADED: 'CABLES_LOADED',
  CABLES_LOADED_BY_PROJECTID: 'CABLES_LOADED_BY_PROJECTID',
  PAGE_UNLOADED: 'PAGE_UNLOADED',
  M3_ITEM_SEARCH: 'M3_ITEM_SEARCH',
  M3_FIND_ITEMS_BY_SERIAL_NUMBER: 'M3_FIND_ITEMS_BY_SERIAL_NUMBER',
  SUPPLIERS_LOADED: 'SUPPLIERS_LOADED',
  TOOL_ASSEMBLIES_LOADED: 'TOOL_ASSEMBLIES_LOADED',
  TOOL_GROUPS_LOADED: 'TOOL_GROUPS_LOADED',
  SAVE_TOOL_ASSEMBLY: 'SAVE_TOOL_ASSEMBLY',
  BHA_TEMPLATES_LOADED: 'BHA_TEMPLATES_LOADED',
  RECEIVE_CREATED_TOOL: 'RECEIVE_CREATED_TOOL',
  TOOL_CONNECTORS_LOADED: 'TOOL_CONNECTORS_LOADED',
  SURFACE_EQUIPMENT_LOADED: 'SURFACE_EQUIPMENT_LOADED',
  RECEIVE_SURFACE_EQUIPMENT_GROUPS: 'RECEIVE_SURFACE_EQUIPMENT_GROUPS',
  SURFACE_EQUIPMENT_UPLOAD_IMAGE: 'SURFACE_EQUIPMENT_UPLOAD_IMAGE',
  SURFACE_EQUIPMENT_LOAD: 'SURFACE_EQUIPMENT_LOAD',
  TOOL_ADDED_TO_TOOL_ASSEMBLY: 'TOOL_ADDED_TO_TOOL_ASSEMBLY',
  SAVE_BHA_TEMPLATE: 'SAVE_BHA_TEMPLATE',
  LOAD_ASSET_HISTORY: 'LOAD_ASSET_HISTORY',
  UPLOAD_3D_OBJECT: 'UPLOAD_3D_OBJECT',
  DELETE_3D_OBJECT: 'DELETE_3D_OBJECT',
  GET_TOOL_ASSEMBLY: 'GET_TOOL_ASSEMBLY',
  LOAD_CABLE: 'LOAD_CABLE',
  REQUEST_BHA_TEMPLATE_FOR_EDITING: 'REQUEST_BHA_TEMPLATE_FOR_EDITING',
  RECEIVE_BHA_TEMPLATE_FOR_EDITING: 'RECEIVE_BHA_TEMPLATE_FOR_EDITING',
  REQUEST_BHA_TEMPLATE_SECTIONS_FOR_EDITING:
    'REQUEST_BHA_TEMPLATE_SECTIONS_FOR_EDITING',
  RECEIVE_BHA_TEMPLATE_SECTIONS_FOR_EDITING:
    'REQUEST_BHA_TEMPLATE_SECTIONS_FOR_EDITING',
  UNLOAD_BHA_TEMPLATE_FOR_EDITING: 'UNLOAD_BHA_TEMPLATE_FOR_EDITING',
  CREATE_TEMPLATE_TOOL: 'CREATE_TEMPLATE_TOOL',
  DELETE_TEMPLATE_TOOL: 'DELETE_TEMPLATE_TOOL',
  REQUEST_UPDATE_TEMPLATE_FOR_EDITING: 'REQUEST_UPDATE_TEMPLATE_FOR_EDITING',
  REQUEST_DUPLICATE_TEMPLATE_ITEM: 'REQUEST_DUPLICATE_TEMPLATE_ITEM',
  REQUEST_SORT_TEMPLATE_TOOLS: 'REQUEST_SORT_TEMPLATE_TOOLS',
  REQUEST_SORT_TEMPLATE_ASSEMBLY_TOOLS: 'REQUEST_SORT_TEMPLATE_ASSEMBLY_TOOLS',
  CREATE_ASSET_HISTORY: 'CREATE_ASSET_HISTORY',
  EDIT_TOOL_IMAGE: 'EDIT_TOOL_IMAGE',
  RECEIVE_EQUIPMENT_ASSETS: 'RECEIVE_EQUIPMENT_ASSETS',
  VALIDATE_TOOLSTRING_SERIAL_NUMBERS: 'VALIDATE_TOOLSTRING_SERIAL_NUMBERS',
  DELETE_TEMPLATE_TOOLASSEMBLY_TOOL: 'DELETE_TEMPLATE_TOOLASSEMBLY_TOOL',
  RECEIVE_STRING_VERIFICATIONS_FOR_TOOLSTRING:
    'RECEIVE_STRING_VERIFICATIONS_FOR_TOOLSTRING',
  SURFACE_EQUIPMENT_BY_CATEGORIES: 'SURFACE_EQUIPMENT_BY_CATEGORIES',
  RECEIVE_CABLE_TEST_DETAILS: 'RECEIVE_CABLE_TEST_DETAILS',
  EXPORT_CABLE_HISTORY: 'EXPORT_CABLE_HISTORY',
};

export const EQIPMENT_CATEGORY_IDS = {
  MWL_TOOLS: 1,
  THIRD_PARTY_TOOLS: 2,
  EWL_TOOLS: 3,
  TOOL_ASSEMBLIES: 4,
};

export const EQUIPMENT_CATEGORY_ENUM = {
  Undefined: 0,
  BCS: 1,
  Cabin: 2,
  BOP: 3,
};

export const EQUIPMENT_CATEGORY_FROMDTO = {
  MechanicalWirelineEquipment: 'MechanicalWirelineEquipment',
  ThirdPartyDownholeEquipment: 'ThirdPartyDownholeEquipment',
  ElectricalWirelineEquipment: 'ElectricalWirelineEquipment',
};

export const MODAL = {
  M3_TOOL: 'M3_TOOL',
  ADD_TOOL: 'ADD_TOOL',
  ADD_SURFACE_EQUIPMENT: 'ADD_SURFACE_EQUIPMENT',
  EDIT_M3_TOOL: 'EDIT_M3_TOOL',
  ADD_BHA_TEMPLATE: 'ADD_BHA_TEMPLATE',
  EDIT_BHA_TEMPLATE: 'EDIT_BHA_TEMPLATE',
  ADD_TOOL_ASSEMBLY_TO_BHA: 'ADD_TOOL_ASSEMBLY_TO_BHA',
  ADD_TOOL_ASSEMBLY: 'ADD_TOOL_ASSEMBLY',
  EDIT_3RDPARTY_TOOL: 'EDIT_3RDPARTY_TOOL',
  ADD_TOOL_TO_TOOL_ASSEMBLY: 'ADD_TOOL_TO_TOOL_ASSEMBLY',
  EDIT_TOOL_ASSEMBLY: 'EDIT_TOOL_ASSEMBLY',
  EDIT_BHA_ADD_TOOL_MODAL: 'EDIT_BHA_ADD_TOOL_MODAL',
  ADD_ASSET_HISTORY_TEST: 'ADD_ASSET_HISTORY_TEST',
  ADD_ASSET_HISTORY_SURFACE: 'ADD_ASSET_HISTORY_SURFACE',
  ADD_ASSET_HISTORY_BHA: 'ADD_ASSET_HISTORY_BHA',
  EDIT_TOOL_IMAGE: 'EDIT_TOOL_IMAGE',
  MISSING_SERIAL_NUMBER: 'MISSING_SERIAL_NUMBER',
  ADD_ASSET_HISTORY_SURFACE_SIL2: 'ADD_ASSET_HISTORY_SURFACE_SIL2',
  ROUTE_BHA_EDITOR: 'ROUTE_BHA_EDITOR',
};

export const MAX_UPLOAD_FILE_SIZE_TOOL_IMG = 512_000; // 512kb

export const UNIT_OF_MEASURE = {
  METRIC: 'metric',
  IMPERIAL: 'imperial',
  CUSTOM: 'Custom',
  PROJECT_DEFAULT: 'Project Default',
};

export const EDITABLE_TOOL_PROPERTIES = {
  OUTER_DIAMETER: 'OUTER_DIAMETER',
  INNER_DIAMETER: 'INNER_DIAMETER',
  LENGTH: 'LENGTH',
  DEPTH: 'DEPTH',
  WEIGHT: 'WEIGHT',
  FISHNECK: 'FISHNECK',
  SWL: 'SWL',
  YIELD: 'YIELD',
  MAXIMUM_OD: 'MAXIMUM_OD',
  MINIMUM_OD: 'MINIMUM_OD',
  MAXIMUM_STANDOFF_FORCE: 'MAXIMUM_STANDOFF_FORCE',
  MINIMUM_STANDOFF_FORCE: 'MINIMUM_STANDOFF_FORCE',
  JAR_ACTIVATION_LIMIT_LOW: 'JAR_ACTIVATION_LIMIT_LOW',
  JAR_ACTIVATION_LIMIT_HIGH: 'JAR_ACTIVATION_LIMIT_HIGH',
  ADDITIONAL_NORMAL_FORCE: 'ADDITIONAL_NORMAL_FORCE',
  AXIAL_FRICTION_REDUCED_TO: 'AXIAL_FRICTION_REDUCED_TO',
  WEIGHT_ABOVE_JARRING_POINT_FACTOR: 'WEIGHT_ABOVE_JARRING_POINT_FACTOR',
};

export const PROJECT_LESSON_REPORT_ACTIVE_TAB = {
  PROJECT_REPORT: 'PROJECT_REPORT',
  PROJECT_LESSONS: 'PROJECT_LESSONS',
  PROJECT_INCIDENTS: 'PROJECT_INCIDENTS',
  PROJECT_NON_PRODUCTIVE_TIME: 'PROJECT_NON_PRODUCTIVE_TIME',
  PROJECT_ASSET_TESTS: 'PROJECT_ASSET_TESTS',
};

export const TOOL_ASSETS_STATUS = {
  TEMPLATE: 'Template',
  PRELIMINARY: 'Preliminary',
  CONFIG_STRUCT: 'Config Struct',
  SERVICEABLE: 'Serviceable',
  SVCEABLE_RESTR: 'Svceable Restr',
  STAND_BY: 'Standy By',
  SPARE: 'Spare',
  UNSERVICEABLE: 'Unserviceable',
  INCIDENT: 'Incident',
  SCRAP_PROPOSAL: 'Scrap Proposal',
  SCRAPPED: 'Scrapped',
  RENAMED: 'Renamed',
};

export const TOOL_ASSET_EVENTS_ENUM = {
  RUN: 1,
  TASK: 2,
  MAINTENANCE: 3,
  COMPLAINTS: 4,
  TEST: 5,
  BOP_TEST: 7,
  STRING_TEST: 8,
  CABLE_TORSION_TEST: 9,
  CABLE_WRAP_TEST: 10,
  CABLE_TORTURE_TEST: 11,
  CABLE_CONTINUITY_TEST: 12,
  CABLE_INSULATION_TEST: 13,
  SIL2_TEST: 14,
  CABLE_WRAP_BRAIDED_MONO_TEST: 15,
  CABLE_BREAK_TEST: 16,
  CABLE_CUT_MAINTENANCE: 17,
  CABLE_SERVICE_MAINTENANCE: 18,
  CABLE_SPOOLING_MAINTENANCE: 19,
};

export const TEST_ASSET_RESULT = {
  PASS: 1,
  WARNING: 2,
  FAIL: 3,
};

export const ASSET_HISTORY_TEST_TYPE = {
  GENERIC: 1,
  CABLE: 2,
  BOP_TEST: 3,
  STRING_TEST: 4,
  CABLE_TORSION_TEST: 5,
  CABLE_WRAP_TEST: 6,
  CABLE_TORTURE_TEST: 7,
  CABLE_CONTINUITY_TEST: 8,
  CABLE_INSULATION_TEST: 9,
  SIL2_TEST: 10,
  CABLE_WRAP_BRAIDED_MONO_TEST: 11,
  CABLE_BREAK_TEST: 12,
};

export const ASSET_HISTORY_EVENT_TYPES_ITEM = {
  TEST: 1,
  MAINTENANCE: 2,
};

export const TEST_RESULT_TO_TOOLTIP_TITLE = {
  [TEST_ASSET_RESULT.PASS]: 'Passed',
  [TEST_ASSET_RESULT.FAIL]: 'Failed',
  [TEST_ASSET_RESULT.WARNING]: 'Warning',
};

export const TASK_STATUS_TO_TOOLTIP_TITLE = {
  [WORK_ITEM_STATUS.STARTED]: 'Started',
  [WORK_ITEM_STATUS.PAUSED]: 'Paused',
  [WORK_ITEM_STATUS.COMPLETED]: 'Objective Met',
  [WORK_ITEM_STATUS.SKIPPED]: 'Skipped',
  [WORK_ITEM_STATUS.FAILED]: 'Objective Not Met',
  [WORK_ITEM_STATUS.NOT_SET]: 'Not Set',
  [WORK_ITEM_STATUS.PLANNED]: 'Planned',
};

export const ASSET_HISTORY_EVENT_TYPES = List([
  { id: '1', name: 'Test' },
  { id: '2', name: 'Maintenance' },
]);

export const BOP_TEST_TYPES = List([
  { id: '1', name: 'Pre Job Test' },
  { id: '2', name: '14 days test' },
  { id: '3', name: 'Yearly test' },
  { id: '4', name: 'Body test' },
]);

export const SIL_VERIFICATION = List([
  { id: '1', name: 'Closed on first attempt', isFail: false },
  { id: '2', name: 'Failed to close on first attempt', isFail: false },
  { id: '3', name: 'Failed during test', isFail: true },
  { id: '4', name: 'Failed during Operation', isFail: true },
  { id: '5', name: 'N/A' },
]);

export const BOP_LEAK_TYPE = List([
  { id: '1', name: 'Outer Seal' },
  { id: '2', name: 'Inner Seal' },
  { id: '3', name: 'Hydraulics' },
  { id: '4', name: 'Equalising' },
  { id: '5', name: 'Pin/Box' },
  { id: '6', name: 'Other' },
]);

export const BOP_RAM = List([
  { id: '1', name: 'Upper Ram' },
  { id: '2', name: 'Middle Ram' },
  { id: '3', name: 'Lower Ram' },
  { id: '4', name: 'Shear Ram' },
  { id: '5', name: 'Pipe Ram' },
  { id: '6', name: 'Slip Ram' },
]);

export const ASSET_HISTORY_EQUIPMENT_TYPE = {
  TOOL: 0,
  CABLE: 1,
  SURFACE: 2,
  BHA: 3,
  ALL: 4,
  STRING_TEST: 5,
  SURFACE_EQUIPMENT: 6,
};

export const ASSET_HISTORY_TEST_TYPES = List([
  {
    id: '1',
    name: 'Generic',
    showByDefault: true,
    description: 'Generic',
    equipmentType: ASSET_HISTORY_EQUIPMENT_TYPE.ALL,
  },
  {
    id: '3',
    name: 'BOP Test',
    showByDefault: true,
    description: 'BOP',
    equipmentType: ASSET_HISTORY_EQUIPMENT_TYPE.SURFACE,
  },
  {
    id: '4',
    name: 'String Test',
    showByDefault: false,
    description: 'String',
    equipmentType: ASSET_HISTORY_EQUIPMENT_TYPE.STRING_TEST,
  },
  {
    id: '5',
    name: 'Cable Torsion Test',
    showByDefault: true,
    description: 'Cable Torsion',
    equipmentType: ASSET_HISTORY_EQUIPMENT_TYPE.CABLE,
  },
  {
    id: '6',
    name: 'Cable Wrap Test Slickline Cable',
    showByDefault: true,
    description: 'Cable Wrap Test Slickline Cable',
    equipmentType: ASSET_HISTORY_EQUIPMENT_TYPE.CABLE,
  },
  {
    id: '7',
    name: 'Cable Torture Test',
    showByDefault: true,
    description: 'Cable Torture',
    equipmentType: ASSET_HISTORY_EQUIPMENT_TYPE.CABLE,
  },
  {
    id: '8',
    name: 'Cable Continuity Test',
    showByDefault: true,
    description: 'Cable Continuity',
    equipmentType: ASSET_HISTORY_EQUIPMENT_TYPE.CABLE,
  },
  {
    id: '9',
    name: 'Cable Insulation Test',
    showByDefault: true,
    description: 'Cable Insulation',
    equipmentType: ASSET_HISTORY_EQUIPMENT_TYPE.CABLE,
  },
  {
    id: '10',
    name: 'Sil2 Test',
    showByDefault: false,
    description: 'Sil2 Test',
    equipmentType: ASSET_HISTORY_EQUIPMENT_TYPE.SURFACE_EQUIPMENT,
  },
  {
    id: '11',
    name: 'Cable Wrap Test Braided/Mono Cable',
    showByDefault: false,
    description: 'Cable Wrap Test Braided/Mono Cable',
    equipmentType: ASSET_HISTORY_EQUIPMENT_TYPE.CABLE,
  },
  {
    id: '12',
    name: 'Cable Break Test',
    showByDefault: false,
    description: 'Cable Break Test',
    equipmentType: ASSET_HISTORY_EQUIPMENT_TYPE.CABLE,
  },
]);

export const ASSET_HISTORY_RESULTS = List([
  { id: '1', name: 'Pass' },
  { id: '2', name: 'Warning' },
  { id: '3', name: 'Fail' },
]);

export const eventHistoryList = Object.keys(TOOL_ASSET_EVENTS_ENUM).map(
  (key) => {
    return {
      id: TOOL_ASSET_EVENTS_ENUM[key],
      description: toolAssetHistoryEventToString(TOOL_ASSET_EVENTS_ENUM[key]),
    };
  },
);

export const EquipmentType = {
  TOOL: 0,
  CABLE: 1,
  SURFACE: 2,
  BHA: 3,
  SURFACE_EQUIPMENT: 3,
};

export const getEventKeyByValue = (value) => {
  switch (value) {
    case TOOL_ASSET_EVENTS_ENUM.RUN:
      return 'run';
    case TOOL_ASSET_EVENTS_ENUM.TASK:
      return 'task';
    case TOOL_ASSET_EVENTS_ENUM.MAINTENANCE:
      return 'maintenance';
    case TOOL_ASSET_EVENTS_ENUM.COMPLAINTS:
      return 'complaints';
    case TOOL_ASSET_EVENTS_ENUM.TEST:
      return 'test';
    case TOOL_ASSET_EVENTS_ENUM.BOP_TEST:
      return 'bopTest';
    case TOOL_ASSET_EVENTS_ENUM.STRING_TEST:
      return 'stringTest';
    case TOOL_ASSET_EVENTS_ENUM.CABLE_TORSION_TEST:
      return 'cableTorsionTest';
    case TOOL_ASSET_EVENTS_ENUM.CABLE_WRAP_TEST:
      return 'cableWrapTest';
    case TOOL_ASSET_EVENTS_ENUM.CABLE_TORTURE_TEST:
      return 'cableTortureTest';
    case TOOL_ASSET_EVENTS_ENUM.CABLE_CONTINUITY_TEST:
      return 'cableContinuityTest';
    case TOOL_ASSET_EVENTS_ENUM.CABLE_INSULATION_TEST:
      return 'cableInsulationTest';
    case TOOL_ASSET_EVENTS_ENUM.SIL2_TEST:
      return 'sil2Test';
    case TOOL_ASSET_EVENTS_ENUM.CABLE_WRAP_BRAIDED_MONO_TEST:
      return 'cableWrapBraidedMonoTest';
    case TOOL_ASSET_EVENTS_ENUM.CABLE_BREAK_TEST:
      return 'cableBreakTest';
    case TOOL_ASSET_EVENTS_ENUM.CABLE_CUT_MAINTENANCE:
      return 'cableCutMaintenance';
    case TOOL_ASSET_EVENTS_ENUM.CABLE_SERVICE_MAINTENANCE:
      return 'cableServiceMaintenance';
    case TOOL_ASSET_EVENTS_ENUM.CABLE_SPOOLING_MAINTENANCE:
      return 'cableSpoolingMaintenance';
    default:
      return null;
  }
};

export const ASSET_HISTORY_TEST_TYPES_DICT = ASSET_HISTORY_TEST_TYPES.reduce(
  (acc, item) => {
    acc[item.id] = item.description;
    return acc;
  },
  {},
);

export const getAssetTestNameById = (id) => {
  return ASSET_HISTORY_TEST_TYPES_DICT[id] || 'Unknown';
};

export const ASSET_HISTORY_MAINTENANCE_TYPE = {
  GENERIC: 1,
  CABLE_CUT: 2,
  CABLE_SERVICE: 3,
  CABLE_SPOOLING: 4,
};

export const ASSET_HISTORY_MAINTENANCE_TYPES = List([
  {
    id: '1',
    name: 'Generic',
    showByDefault: true,
    description: 'Generic',
    equipmentType: ASSET_HISTORY_EQUIPMENT_TYPE.ALL,
  },
  {
    id: '2',
    name: 'Cable Cut',
    showByDefault: true,
    description: 'Cable Cut Maintenance',
    equipmentType: ASSET_HISTORY_EQUIPMENT_TYPE.CABLE,
  },
  {
    id: '3',
    name: 'Cable Service',
    showByDefault: true,
    description: 'Cable Service Maintenance',
    equipmentType: ASSET_HISTORY_EQUIPMENT_TYPE.CABLE,
  },
  {
    id: '4',
    name: 'Cable Spooling',
    showByDefault: true,
    description: 'Cable Spooling Maintenance',
    equipmentType: ASSET_HISTORY_EQUIPMENT_TYPE.CABLE,
  },
]);

export const ASSET_HISTORY_MAINTENANCE_TYPES_DICT =
  ASSET_HISTORY_MAINTENANCE_TYPES.reduce((acc, item) => {
    acc[item.id] = item.description;
    return acc;
  }, {});

export const getAssetMaintenanceNameById = (id) => {
  return ASSET_HISTORY_MAINTENANCE_TYPES_DICT[id] || 'Unknown';
};
