import { initialize } from 'redux-form/immutable';
import { replace } from 'connected-react-router/immutable';

import {
  TASK_FORMS,
  TASK_ACTIONS,
  TASKS_EDIT_TASK_MODAL_ID,
  TASKS_CREATE_TASK_MODAL_ID,
  TASK_SURFACE_EQUIPMENT_ACTIONS,
} from 'features/projects/tasks/tasks.constants';

import { toggleModal } from 'app/app.actions';
import taskService from 'services/task.service';
import { toProjectTasks } from 'utils/route.util';
import { invokeIfFunction } from 'utils/app.util';
import { NOTIFICATION_VARIANTS } from 'app/app.constants';
import tasksMapper from 'features/projects/tasks/tasks.mappers';
import { getProjectById } from 'features/projects/projects.actions';
import { getAllProjectActivities } from 'features/projects/activities/activities.actions';
import { loadSimulations } from 'features/projects/tasks/task/simulation/simulation.actions';
import { SimulationState } from 'features/projects/tasks/task/simulation/simulation.constants';
import { getToolstringsForTask } from 'features/projects/tasks/task/toolstring/toolstring.actions';

export const addTasksFromTemplate =
  (projectId, selectedTemplateTasks) => (dispatch) => {
    const payload = taskService
      .addTaskTemplates(
        projectId,
        selectedTemplateTasks.map((templateTask) => templateTask.get('id')),
      )
      .then((tasks) => {
        dispatch(getAllProjectActivities(projectId));
        return tasks;
      })
      .then((tasks) =>
        Promise.all(
          tasks
            .filter((task) => task.hasToolStrings)
            .map((task) =>
              dispatch(getToolstringsForTask(projectId, task.taskId)),
            ),
        )
          .then(() => dispatch(getProjectById(projectId)))
          .then(() => {
            dispatch(
              toggleModal({
                modalId: TASKS_CREATE_TASK_MODAL_ID,
                value: false,
              }),
            );
          }),
      );

    dispatch({
      payload,
      projectId,
      type: TASK_ACTIONS.ADD_TASKS_FROM_TEMPLATE,
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]: `${selectedTemplateTasks.size} item(s) was successfully created`,
      },
    });

    return payload;
  };

export const createTask = (projectId) => (dispatch) => (task) => {
  dispatch({
    type: TASK_ACTIONS.CREATE_TASK,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: 'A new task was successfully created',
    },
    payload: () =>
      taskService.createTask(projectId, task.toJS()).then((Task) => {
        const { taskId } = Task;
        dispatch(replace(toProjectTasks(projectId, taskId)));
        dispatch(getProjectById(projectId));
        return dispatch(
          toggleModal({
            modalId: TASKS_CREATE_TASK_MODAL_ID,
            payload: Task,
            value: false,
          }),
        );
      }),
  });
};

export const editTask = (index) => (dispatch) =>
  dispatch(
    toggleModal({
      modalId: TASKS_EDIT_TASK_MODAL_ID,
      activeIndex: index,
    }),
  );

export const createTaskModalContainerOnLoad = () => (dispatch) => {
  dispatch(initialize(TASK_FORMS.NEW_TASK.FORM_ID, tasksMapper.Task.initial));
  dispatch({
    type: TASK_ACTIONS.CREATE_TASK_MODAL_LOADED,
    payload: () => taskService.getAllTaskTemplates(),
  });
};

export const getAllTasks = (projectId) => (dispatch) => {
  const payload = taskService.getAllTasks(projectId);

  dispatch({
    type: TASK_ACTIONS.GET_ALL_TASKS,
    payload,
  });

  return payload;
};

export const tasksContainerOnUnload = () => (dispatch) =>
  dispatch({ type: TASK_ACTIONS.PROJECT_TASKS_PAGE_UNLOADED });

export const confirmDeleteTask = (projectId, taskId) => (dispatch) => {
  const payload = taskService
    .deleteTask(projectId, taskId)
    .then(() => dispatch(getProjectById(projectId)));

  dispatch({
    payload,
    projectId,
    type: TASK_ACTIONS.CONFIRM_DELETE_TASK,
  });

  return payload;
};

export const deleteTask = (projectId, taskId, callback) => (dispatch) =>
  dispatch({
    id: taskId,
    type: TASK_ACTIONS.DELETE_TASK,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: 'The task was successfully deleted',
    },
    confirmationDialog: {
      description:
        'Are you sure you want to delete this task? All activities will be lost',
      title: 'Delete task',
      confirmButtonText: 'Delete',
    },
    payload: () =>
      dispatch(confirmDeleteTask(projectId, taskId)).then(() =>
        invokeIfFunction(callback),
      ),
  });

export const sortTasks =
  (projectId, { oldIndex, newIndex }, tasks) =>
  (dispatch) => {
    if (oldIndex === newIndex) return;

    const sortedTask = tasks.get(oldIndex);
    const affectedTask = tasks.get(newIndex);

    const itemSequence = {
      sortedItemId: sortedTask.get('id'),
      affectedItemId: affectedTask.get('id'),
    };

    dispatch({
      projectId,
      oldIndex,
      newIndex,
      type: TASK_ACTIONS.SORT_TASKS,
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]: 'The task was successfully updated',
      },
      payload: () =>
        taskService.updateTaskSequence(
          projectId,
          sortedTask.get('id'),
          itemSequence,
        ),
    });
  };

export const copyTask = (projectId, taskId) => (dispatch) => {
  const payload = taskService.copyTask(projectId, taskId).then((newTask) => {
    dispatch(getAllTasks(projectId));

    newTask.hasToolStrings
      ? dispatch(getToolstringsForTask(projectId, newTask.taskId))
      : Promise.resolve();

    return newTask;
  });

  dispatch({
    payload,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: 'The task was successfully copied',
    },
    type: TASK_ACTIONS.COPY_TASK,
  });

  return payload;
};

export const receiveTask = (task) => ({
  payload: task,
  type: TASK_ACTIONS.RECEIVE_TASK,
});

export const onLoadTaskSurfaceEquipment = (projectId, taskId) => (dispatch) => {
  dispatch(getTaskCables(projectId, taskId));
  dispatch(getTaskSurfaceEquipment(projectId, taskId));

  dispatch({
    type: TASK_SURFACE_EQUIPMENT_ACTIONS.PAGE_LOADED,
  });
};

export const getTaskCables = (projectId, taskId) => (dispatch) => {
  dispatch({
    type: TASK_SURFACE_EQUIPMENT_ACTIONS.CABLES_LOADED,
    payload: () => taskService.getCablesForTask(projectId, taskId),
  });
};

export const getTaskSurfaceEquipment = (projectId, taskId) => (dispatch) => {
  dispatch({
    type: TASK_SURFACE_EQUIPMENT_ACTIONS.SURFACE_EQUIPMENT_LOADED,
    payload: () => taskService.getSurfaceEquipmentForTask(projectId, taskId),
  });
};

export const saveCableToTask = (taskCable, projectId, taskId) => (dispatch) => {
  const payload = taskService.saveCableToTask(taskCable, projectId, taskId);

  dispatch({
    payload,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: 'Cable successfully added',
    },
    type: TASK_SURFACE_EQUIPMENT_ACTIONS.SAVE_CABLE,
  });

  return payload;
};

export const saveSurfaceToTask =
  (surfaceTool, projectId, taskId) => (dispatch) => {
    dispatch({
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]: 'Tool successfully added!',
      },
      type: TASK_SURFACE_EQUIPMENT_ACTIONS.SAVE_SURFACE_EQUIPMENT,
      payload: () =>
        taskService.saveSurfaceToolToTask(surfaceTool, projectId, taskId),
    });
  };

export const deleteCableFromTask =
  (taskCable, projectId, taskId) => (dispatch) => {
    const payload = taskService.deleteCableFromTask(
      taskCable,
      projectId,
      taskId,
    );

    return dispatch({
      payload,
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]: 'Cable successfully removed',
      },
      type: TASK_SURFACE_EQUIPMENT_ACTIONS.DELETE_CABLE,
    });
  };

export const deleteSurfaceEquipmentFromTask =
  (surfaceEquipment, projectId, taskId) => (dispatch) => {
    const payload = taskService.deleteSurfaceFromTask(
      surfaceEquipment,
      projectId,
      taskId,
    );

    return dispatch({
      payload,
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]: 'Equipment successfully removed',
      },
      type: TASK_SURFACE_EQUIPMENT_ACTIONS.DELETE_CABLE,
    });
  };

export const saveCableSerialNumberToTask = (
  projectId,
  taskId,
  individualSerialNumber,
) => ({
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: 'Serial number saved',
  },
  type: TASK_SURFACE_EQUIPMENT_ACTIONS.SAVE_CABLE_SERIAL_NUMBER,
  payload: {
    projectId,
    taskId,
    individualSerialNumber,
  },
});

// TODO: Move to saga
export const saveSurfaceEquipmentSerialNumber =
  (projectId, taskId, individualSerialNumber, id) => (dispatch) => {
    const dto = {
      projectId,
      taskId,
      individualSerialNumber,
      id,
    };

    const payload = taskService
      .saveSurfaceEquipmentSerialNumber(dto)
      .then(() => {
        dispatch(getTaskSurfaceEquipment(projectId, taskId));
      });

    return dispatch({
      payload,
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]: 'Serial number saved',
      },
      type: TASK_SURFACE_EQUIPMENT_ACTIONS.SAVE_SURFACE_SERIAL_NUMBER,
    });
  };

export const copySerialsForSEFromAnotherTask =
  (projectId, taskId, fromTaskId) => (dispatch) => {
    const payload = taskService
      .updateTaskSEFromToTask(projectId, fromTaskId, taskId)
      .then(() => {
        dispatch(getTaskCables(projectId, taskId));
        dispatch(getTaskSurfaceEquipment(projectId, taskId));
        dispatch(
          loadSimulations(projectId, taskId, [
            SimulationState.PLANNED,
            SimulationState.UPDATED_PLANNED,
          ]),
        );
        dispatch(getAllTasks(projectId));
      });

    return dispatch({
      payload,
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]: 'Serial numbers copied',
      },
      type: TASK_SURFACE_EQUIPMENT_ACTIONS.UPDATE_TASK_SURFACE_EQUIPMENT_SERIALS_FROM_TO,
    });
  };
